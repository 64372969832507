import Vue from 'vue'
import './set-public-path'
import App from './App.vue'
import VueI18n from "vue-i18n";
import axios from 'axios'
//import EN from "./localization/en";
import router from "./router";
import commonMixin from '../src/assets/common/vue-common.js'
import Multiselect from 'vue-multiselect'
import ListingcommonMixin from '../src/assets/common/vue-commonList.js'
import DatePicker from 'v-calendar/src/components/DatePicker'
import ListingStatus from './components/Common/ListingStatus.vue';	
import ModuleHeader from '../src/components/Header/ModuleHeader.vue';
import breadcrumb from './components/Common/BreadCrumb.vue'
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useloggedUserInfo } from '../src/stores/UserInfo';
import {TgList} from 'tg-listv/dist/tglistv.common'
import { DynamicForm } from 'tg-controls_cli/dist/myLib.common'
import singleSpaVue from 'single-spa-vue';
import Loader from './components/Common/Loader.vue'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
const resourceFilePath = process.env.VUE_APP_LANGUAGE_FILE_URL;
const envType = process.env.VUE_APP_NODE_ENV;
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Vue2ColorPicker from 'vue2-color-picker';
import vuescroll from 'vuescroll';
import moment from 'moment';

 var $ = require('jquery');
Vue.config.productionTip = false;
Vue.prototype.$= $;
require('./vee-validate');
 Vue.use(VueI18n);
 Vue.use(PiniaVuePlugin);
 Vue.component('loader', Loader)
 Vue.component('breadcrumb', breadcrumb);
 Vue.use(Vue2ColorPicker);
 Vue.use(vuescroll);  
 Vue.component('Multiselect', Multiselect);
 Vue.component('listing-status', ListingStatus);
 Vue.component('tg-list',TgList);
 Vue.component('dynamic-form',DynamicForm); 
 Vue.component('ModuleHeader',ModuleHeader);
 Vue.use(VueTelInput);
 Vue.use(CKEditor);
 Vue.component('v-date-picker', DatePicker)
 const pinia=createPinia();
 window.pinia = pinia;
 Vue.use(pinia);
 let parsedLocalStorage = localStorage.getItem('TALGYEN_NAVBAR_SETTING');
 let selectedLang = "en"; // Default to English
 if(parsedLocalStorage) {
  const settings = JSON.parse(parsedLocalStorage);
  selectedLang = settings.lang.toLowerCase() || "en"; // Use stored language or default to English
}
const i18n = new VueI18n({
  locale: selectedLang,
  messages: {
  },
});
// const i18n = new VueI18n({
//   locale: "en",
//   messages: {
//     en:EN,
//   },
// });
Vue.mixin(commonMixin, ListingcommonMixin);
const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  // axios.defaults.headers.common['Accept-Language'] = lang
  // document.querySelector('html').setAttribute('lang', lang)
  // return lang
}

async function loadLanguageAsync (lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }

  axios.get(`${resourceFilePath}/${envType}/${lang}.js`).then(response => {
    let msgs = response.data
    loadedLanguages.push(lang)
    i18n.setLocaleMessage(lang, response.data[0])
  })
}
// router.js
router.beforeEach((to, from, next) => {
  loadLanguageAsync(selectedLang).then(() => next());
})
const containerSelector = '#page-2-container'
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    pinia,
    i18n,
    router,
    render(h) {     
      return h(App, {
         props: {          
          props: {          
            userProfile:useloggedUserInfo().setUser(this.userProfile),
            classicEditor:useloggedUserInfo().setClassicEditor(this.classicEditor) 
           } 
        } 
      });//
    },
    el: containerSelector
    
  }
});
Vue.filter('formatDate', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('MM/DD/YYYY'))
}
})
Vue.filter('NewformatDate', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('DD-MM-YYYY'))
}
})
Vue.filter('formatDatee', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('YYYY-MM-DD'))
}
})
Vue.filter('formatTimee', function (value){
  if (value) {
    return moment(value, "HH:mm:ss").format(String('h:mm A'));
}
})
Vue.filter('formatDateTime', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format(String('MM/DD/YYYY h:mm A'))
}
})
Vue.filter('currency', function (value){
  if (value) {
    //return moment(value, "YYYY-MM-DD").format(String('MM/DD/YYYY'))
}
})
Vue.filter('Time', function (value) {
  if (value) {
    return moment(value).format('h:mm A');
  }
});
Vue.filter('Timee', function (value) {
  if (value) {
    return moment(value).format('HH:mm');
  }
});


export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
