import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  async GetContextMenu(params) {
  return await http.instanceCommon.get("/Common/GetContextMenu?"+params);
  }
  async CheckPrivilege(params){
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  } 
  async GroupListing(params){    
    return await http.instance.get("/Form/GroupListing?" + params);
  }
  async JobListing(params){   
    return await http.instance.get("/Jobs/JobListing?" + params);
  }
  async RequisitionList(params){   
    return await http.instance.get("/Jobs/RequisitionList?" + params);
  }
  async RequisitionDetail(params){   
    return await http.instance.get("/Jobs/RequisitionDetail?" + params);
  }
  async SaveRequisition(params){   
    return await http.instance.post("/Jobs/SaveRequisition" , { postString: params });
  }
  async GetLocationList(params) {  
    return await http.instance.get("/Form/GetDDLListByFunction?" + params);
  }
  async GetLicencedUserList(params){     
    return await http.instance.get("/Form/GetLicencedUsers?" + params);
  }
  async GetTalentPoolList(params){     
    return await http.instance.get("/Candidate/CandidateListing?" + params);
  }
  async CommonCommonDelete(params) {
    return await http.instanceCommon.post("/Common/CommonDelete", { postString: params });
  }
  async CheckPrivilege(params) {
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  async SaveRecruiterGroup(params) {
    return await http.instance.post("/Form/SaveRecruiterGroup", { postString: params });
  }
  async GetRecruiterList(params) {
    return await http.instance.get("/Candidate/GetRecruiterList?"+ params );
  }
  async GroupExists(params) {
    return await http.instance.get("/Form/GroupExists?"+params);
  }
  async GetDDLData(params) {  
    return await http.instanceCommon.get("/Common/GetDDLData?" + params);
  }
  async CandidateList(params) {  
    return await http.instance.get("/Candidate/SearchCandidate?" + params);
  }
  async ClearView(params) {
    return await http.instanceCommon.post("/FormLayout/ClearView", { postString: params });
  }
  async GetViewList(params) {
    return await http.instanceCommon.get("/FormLayout/GetViewList?" + params);
  }
  async GetViewById(params) {
    return await http.instanceCommon.get("/FormLayout/GetViewById?" + params);
  }
  async ApplyView(params) {
    return await http.instanceCommon.post("/FormLayout/ApplyView", { postString: params });
  }
  async SaveView(params) {
    return await http.instanceCommon.post("/FormLayout/SaveView", { postString: params });
  }
  async ViewListFields(params) {
    return await http.instanceCommon.get("/FormLayout/ViewListFields?" + params);
  }
  async ViewListUsers(params) {
    return await http.instanceCommon.get("/FormLayout/ViewListUsers?" + params);
  }
  async SearchCriteriaListing(params) {
    return await http.instanceCommon.get("/Common/SearchCriteriaListing/", { params });
  }
  async GetFieldName(params) {
    return await http.instanceCommon.get("/Common/GetFieldName?"+ params);
}
async GetOperator(params) {
  return await http.instanceCommon.get("/Common/GetOperator?"+ params);
}
async SearchCriteriaById(params) {
  return await http.instance.get("/Form/SearchCriteriaById?"+params);
}
async SearchCriteriaByIdView(params) {
  return http.instanceCommon.get("/Common/ViewFlterById?"+params);
}
async GetDynamicForm(params) {  
  return await http.instanceCommon.get("/FormLayout/GetForm?" + params);
}
async DocumentTypes(params) {  
  return await http.instance.get("/Candidate/ManageDocumentTypes?" + params);
}
async GetConfigurationValueByKey(params) {    
  return await http.instanceCommon.get("/Common/GetConfigurationValueByKey?" + params);
}
async CandidateDetails(params) {
  return await http.instance.get("/Candidate/CandidateDetails?" + params);
}
async AddAsCandidate(params) {
  return await http.instance.post("/Form/AddAsCandidate", { postString: params });
}
async GetDocumentTypes(params) {    
  return await http.instance.get("/Candidate/GetDocumentTypes?" + params);
}
async AddCandidate(params) {
  return await http.instance.post("/Candidate/AddCandidate", { postString: params });
}
async TalentPoolDetails(params){   
  return await http.instance.get("/Jobs/TalentPoolDetails?" + params);
}
async GetDocumentsList(params) {  
  return await http.instance.get("/Candidate/GetDocumentsList?" + params);
}
async DownloadFile(params){
  return await http.instanceCommon.get("/Common/DownloadFile?"+params);
}
async ManageTags(params) {
  return await http.instance.get("/Form/ManageTags?"+params);
}
async DeleteItems(params) {  
  return await http.instance.get("/Candidate/DeleteItems?" + params);
}
async ManageIndustry(params) {  
  return await http.instance.get("/Form/ManageIndustry?" + params);
}
async ManageTags(params) {
  return await http.instance.get("/Form/ManageTags?"+params);
}
async SaveHRJob(params) {
  return await http.instance.post("/Form/SaveHRJob", { postString: params });
}
async ManageFunctionalArea(params) {  
  return await http.instance.get("/Form/ManageFunctionalArea?"+params);
}
async DeleteDocuments(params) {  
  return await http.instance.get("/Candidate/DeleteDocuments?" + params);
}
async SaveSkill(params) {
  return await http.instance.post("/Form/SaveSkill", { postString: params });
}
async GetIndustryIcons(params) {  
  return await http.instance.get("/Candidate/GetIcons?" + params);
}
async SaveIndustry(params) {
  return await http.instance.post("/Form/SaveIndustry", { postString: params });
}
async Savetag(params) {
  return await http.instanceCommon.post("/Tag/SaveTag", { postString: params });
}
async SaveDocType(params) {
  return await http.instance.post("/Form/SaveDocType", { postString: params });
}
async EditGetDocumentType(params) {
  return await http.instance.post("/Candidate/EditGetDocumentType", { postString: params });
}
async SaveFunctionalArea(params) {
  return await http.instance.post("/Form/SaveFunctionalArea", { postString: params });
}
async GetFileSrc(params) {    
  return await http.instance.post("/Form/GetFileSrc", { postString: params });
}
async GetCandidateDetails(params) {  
  return await http.instance.get("/Candidate/GetCandidateDetails?" + params);
}
async GetPersonalinformation(params){    
  return await http.instance.get("/Form/GetPersonalinformation?" + params);
}
async SavePersonalInformation(params) {
  return await http.instance.post("/Form/SavePersonalInformation", { postString: params });
}
async GetOfferCompensation(params){    
  return await http.instance.get("/Form/GetOfferCompensation?" + params);
}
async SaveCompensationInformation(params) {
  return await http.instance.post("/Form/SaveCompensationInformation", { postString: params });
}
async GetEmailTemplate(params){    
  return await http.instance.get("/Form/GetEmailTemplate?" + params);
}
async GetEmployementInformation(params){    
  return await http.instance.get("/Form/GetEmployementInformation?" + params);
}
async HRCandidateDetails(params){    
  return await http.instance.get("/Hiring/CandidateDetails?" + params);
}
async MailOffer(params) {
  return await http.instance.post("/Form/MailOffer", { postString: params });
}
async SaveEmployementInformation(params) {
  return await http.instance.post("/Form/SaveEmployementInformation", { postString: params });
}
async GetOfferStatus(params) {  
  return await http.instance.get("/Candidate/GetOfferStatus?" + params);
}
async GetStages(params) {  
  return await http.instance.get("/Candidate/GetStages?" + params);
}
async GetStatusCandidate(params) {  
  return await http.instance.get("/Candidate/GetStatusCandidate?" + params);
}
async ManageDesignation(params) {  
  return await http.instance.get("/Form/ManageDesignation?"+params);
}
async SaveDesignation(params) {
  return await http.instance.post("/Form/SaveDesignation", { postString: params });
}
async UpdateCandidateStage(params) {
  return await http.instance.post("/Form/UpdateCandidateStage", { postString: params });
}
async GetProfileUrl(params){    
  return await http.instance.get("/Hiring/GetProfileUrl?" + params);
}
async DownloadFileStreamFromAzure(params){    
  return await http.instance.get("/Hiring/DownloadFileStreamFromAzure?" + params);
}
async ManageDepartment(params) {  
  return await http.instance.get("/Form/ManageDepartment?" + params);
}
async SaveDepartment(params) {
  return await http.instance.post("/Form/SaveDepartment", { postString: params });
}
async ManageJobType(params) {  
  return await http.instance.get("/Candidate/ManageJobType?" + params);
}
async DeleteJobTypes(params) {  
  return await http.instance.get("/Candidate/DeleteJobTypes?" + params);
}
async Saveproducttype(params) {
  return await http.instance.post("/Form/Saveproducttype", { postString: params });
}
async ManageSkills(params) {  
  return await http.instance.get("/Form/ManageSkills?" + params);
}
async SaveSkill(params) {
  return await http.instance.post("/Form/SaveSkill", { postString: params });
}
async GetSpecilizationStream(params) {  
  return await http.instance.get("/Form/GetSpecilizationStream?" + params);
}
async SaveEducationQualification(params) {
  return await http.instance.post("/Form/SaveEducationQualification", { postString: params });
}
async SaveIndustry(params) {
  return await http.instance.post("/Form/SaveIndustry", { postString: params });
}
async GetInterviews(params){     
  return await http.instance.get("/Candidate/GetInterviews?" + params);
}
async GetRejectInverView(params){     
  return await http.instance.get("/Candidate/GetRejectInverView?" + params);
}
async GetNotes(params){     
  return await http.instance.get("/Candidate/GetNotes?" + params);
}
async AddNotes(params) {
  return await http.instance.post("/Candidate/AddNotes", { postString: params });
}
async GetStagesData(params){     
  return await http.instance.get("/Form/GetStagesData?" + params);
}
async AddDocuments(params) {
  return await http.instance.post("/Candidate/AddDocuments", { postString: params });
}
async AddQueryString(params) {
  return await http.instance.post("/Candidate/AddQueryString", { postString: params });
}
async AddFeedback(params) {
  return await http.instance.post("/Candidate/AddFeedback", { postString: params });
}
async GetFeedback(params){     
  return await http.instance.get("/Candidate/GetFeedback?" + params);
}
async GetFeedbackHistory(params){     
  return await http.instance.get("/Candidate/GetFeedbackHistory?" + params);
}
async JobDetails(params){     
  return await http.instance.get("/Jobs/JobDetails?" + params);
}
async GetDashBoardWidget(params){     
  return await http.instance.get("/Dashboard/GetDashBoardWidget?" + params);
}
async GetCandidateListingByPostId(params){     
  return await http.instance.get("/Candidate/GetCandidateListingByPostId?" + params);
}
async DeleteDocument(params) {
  return await http.instance.post("/Form/DeleteDocument", { postString: params });
}
async GetRating(params){     
  return await http.instance.get("/Candidate/GetRating?" + params);
}
async CandidateProfileFieldDetails(params){
  return await http.instance.get("/Form/CandidateProfileFieldDetails?" + params);
}
async SaveEmailData(params) {
  return await http.instance.post("/Form/SaveEmailData", { postString: params });
}
async GetProfileTemplates(params){   
  return await http.instance.get("/Form/GetProfileTemplates?" + params);
}
async SaveProfileTemplateData(params) {
  return await http.instance.post("/Form/SaveProfileTemplateData", { postString: params });
}
async GetCandidateQuestionaireData(params){     
  return await http.instance.get("/Candidate/GetCandidateQuestionaireData?" + params);
}
async SendEmail(params) {
  return await http.instance.post("/Form/SendEmail", { postString: params });
}
async ManageApplicantStatus(params) {
  return await http.instance.post("/Candidate/ManageApplicantStatus", { postString: params });
}
async ManageRejectReason(params) {  
  return await http.instance.get("/Form/ManageRejectReason?" + params);
}
async SaveRejectReasons(params) {
  return await http.instance.post("/Form/SaveRejectReasons", { postString: params });
}
async SaveReasons(params) {
  return await http.instance.post("/Form/SaveReasons", { postString: params });
}
async GetApplicantCountOnJob(params){     
  return await http.instance.get("/Candidate/GetApplicantCountOnJob?" + params);
}
async UnHireCandidate(params){     
  return await http.instance.get("/Candidate/UnHireCandidate?" + params);
}
async GetFeedBackListing(params){     
  return await http.instance.get("/Form/GetFeedBackListing?" + params);
}
async statusdropdown(params) {
  return await http.instanceCommon.get("/Common/GetStatusDDLData?" + params);
}
async GetRecruiterFilledTimeSLots(params) {
  return await http.instance.get("/Candidate/GetRecruiterFilledTimeSLots?"+ params );
}
async GetSchIntStatus(params) {
  return await http.instance.get("/Candidate/GetScheduledInterviewStatus?"+ params);
}
async SaveScheduleInterview(params) {
  return await http.instance.post("/Candidate/SaveScheduleInterview", { postString: params });
}
async GetEmployeeRequestsList(params){  
  return await http.instance.get("/Candidate/GetEmployeeRequestsList?" + params);
}
async DeleteRequest(params){  
  return await http.instance.get("/Candidate/DeleteRequest?" + params);
}
async GetEmployeeExitListing(params) {
  return await http.instance.get("/Form/GetEmployeeExitListing?" + params);
}
async PolicyListing(params){   
  return await http.instance.get("/Form/PolicyListing?" + params);
}
async GetRequestModuleForService(params){  
  return await http.instance.get("/Candidate/GetRequestModuleForService?" + params);
}
async DeleteCandidateByPostId(params) {
  return await http.instance.post("/Candidate/DeleteCandidateByPostId", { postString: params });
}
async InterviewierDetails(params){     
  return await http.instance.get("/Form/InterviewierDetails?" + params);
}
async UpdateStageData(params) {
  return await http.instance.post("/Form/UpdateStageData", { postString: params });
}
async GetVendorListing(params) {
  return await http.instance.get("/Form/GetVendorListing?" + params);
}
async GetServiceListing(params) {
  return await http.instance.get("/Form/GetServiceListing?" + params);
}
async GetRevokeDetail(params) {
  return await http.instance.get("/Candidate/GetRevokeDetail?" + params);
}
async SaveRevokeLicenses(params) {
  return await http.instance.get("/Candidate/SaveRevokeLicenses?" + params);
}
async AddInterviewStage(params) {
  return await http.instance.post("/Candidate/AddInterviewStage", { postString: params });
}
async GetGroupData(params) {
  return await http.instance.get("/Form/GetGroupData?" + params);
}
async SaveInterviewerList(params) {
  return await http.instance.post("/Form/SaveInterviewerList", { postString: params });
}
async ParsePdf(params){
  return http.instance.post("/Candidate/ParsePdf",params);
} 
async GetEmailTemplateID(params){
  return await http.instance.get("/Candidate/GetEmailTemplateID?" + params);
}
async GetEmployeeResignationListing(params) {
  return await http.instance.get("/Form/GetEmployeeResignationListing?" + params);
}
async TalentPoolList(params){   
  return await http.instance.get("/Jobs/TalentPoolList?" + params);
}
async GetLinkedCompanyDetail(params){
  return await http.instance.get("/Candidate/GetLinkedCompanyDetail?" + params);
}
async GetProjectList(){
  return await http.instance.get("/Candidate/GetProjectList");
}
async GetRequestData(params){
  return await http.instance.get("/Candidate/GetRequestData?" + params);
}
async BindVendorServicesByVendorWise(params){
  return await http.instance.get("/Candidate/BindVendorServicesByVendorWise?" + params);
}
async GetUserList(params) {  
  return await http.instance.get("/Form/GetUserList?" + params);
}
async GetUserDetailsByUserId(params) {
  return await http.instance.get("/Form/GetUserDetailsByUserId?" + params);
}
async SaveExitEmployee(params) {
  return await http.instance.post("/Form/SaveExitEmployee", { postString: params });
}
async AddVendor(params) {
  return await http.instance.post("/Form/AddVendor", { postString: params });
}
async CheckEmail(params) {
  return await http.instance.get("/Form/CheckEmail?" + params);
}
async GetEmployeeExitDetails(params) {
  return await http.instance.get("/Form/GetEmployeeExitDetails?" + params);
}
async GetProbationNoticePeriod(params) {
  return await http.instance.get("/Form/GetProbationNoticePeriod?" + params);
}
async GetHideContextMenuConfig(){
  return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
}
async SaveHideContextMenuConfig(param){
  return await http.instanceCommon.post("/Common/SaveHideContextMenuConfig?"+param);
}
async GetModuleMasterIdByModuleName(params){ 
  return await http.instanceCommon.get("/Common/GetModuleMasterIdByModuleName?"+params);
}
async GetConfigurationValueForCompanyByKey(param){
  return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?"+param);
}
async GetCompanyLicense(params){
  return await http.instance.get("/Candidate/GetCompanyLicense?" + params);
}
async SaveRequestEmployee(params){
  return await http.instance.get("/Candidate/SaveRequestEmployee?" + params);
}
async GetCompanySevices(params){
  return await http.instance.get("/Candidate/GetCompanySevices?" + params);
}
async AddEditPolicy(params) {
  return await http.instance.post("/Form/AddEditPolicy", { postString: params });
}
async GetPolicyDataById(params) {
  return await http.instance.get("/Form/GetPolicyDataById?" + params);
}
async GetStatusList(params) {      
  return await http.instanceCommon.get("/Common/GetStatusList?" + params );
}
async GetModuleList() {  
  return await http.instance.get("/Candidate/GetModuleList");
}
async AddFeedbackk(params) {
  return await http.instance.post("/Candidate/AddFeedbackk", { postString: params });
}
async GetFeedBackExitDetails(params) {
  return await http.instance.get("/Form/GetFeedBackExitDetails?" + params);
}
async GetEducation(params) {
  return await http.instance.get("/Form/GetEducation?" + params);
}
async BindCompanyDropdown(){
  return await http.instance.get("/Candidate/BindCompanyDropdown");
}
async AssociateCompanies(params){
  return await http.instance.post("/Candidate/AssociateCompanies?"+params);
}
async GetDocatrate(params) {
  return await http.instance.get("/Form/GetDocatrate?" + params);
}
async GetStatusHistoryListing(params) {
  return await http.instance.get("/Form/GetStatusHistoryListing?" + params);
}
async GetExpectedReleaveDate(params) {
  return await http.instance.get("/Form/GetExpectedReleaveDate?" + params);
}
async ResignationStatus(params) {
  return await http.instance.post("/Form/ResignationStatus", { postString: params });
}
async SaveEmployeeResignaton(params) {
  return await http.instance.post("/Form/SaveEmployeeResignaton", { postString: params });
}
async HRCommonDelete(params) {
  return await http.instance.post("/Form/CommonDelete", { postString: params });
}
async GetEmployeeResignatonExitDetails(params) {
  return await http.instance.get("/Form/GetEmployeeResignatonExitDetails?" + params);
}
async AssociatedVendors(params){
  return await http.instance.get("/Candidate/AssociatedVendors?" +params);
}
async SearchVendors(params){  
  return await http.instance.get("/Candidate/SearchVendors?"+params);
}
async GetSearchActiveCategoriesForVendor(params){  
  return await http.instance.get("/Candidate/GetSearchActiveCategoriesForVendor?"+params);
}
async GetCompanyLocationByVendor(params){  
  return await http.instance.get("/Candidate/GetCompanyLocationByVendor?"+params);
}
async GetFeedbackQuestions(params) {
  return await http.instance.get("/Form/GetFeedbackQuestions?" + params);
}
async AddServices(params) {
  return await http.instance.post("/Form/AddServices", { postString: params });
}
async GetServiceDDlData(params) {
  return await http.instance.get("/Form/GetServiceDDlData?" + params);
}
async AssignServices(params){
  return await http.instance.post("/Form/AssignServices?"+params);
}
async GetJobPostRequisitionDetails(params) {
  return await http.instance.get("/Form/GetJobPostRequisitionDetails?" + params);
}
async AssociateVendor(params){   
  return await http.instance.get("/Candidate/AssociateVendor?"+params);
}
async GetRequestRaisedForMe(params){   
  return await http.instance.get("/Candidate/GetRequestRaisedForMe?"+params);
}
async GetApprovalChain(params) {
  return await http.instance.get("/Form/GetApprovalChain?" + params);
}
async SaveReject(params) {
  return await http.instance.post("/Form/SaveReject", { postString: params });
}
async SaveApproveRequisition(params){   
  return await http.instance.post("/Jobs/SaveApproveRequisition" , { postString: params });
}
async ApprovalChainList(params) {  
  return await http.instance.get("/Form/ApprovalChainList?" + params);
}
async GetPosts(){   
  return await http.instance.get("/Candidate/GetPosts");
}
async GetStatusList(params){ 
  return await http.instanceCommon.get("/Common/GetStatusList?"+params);
}
async ReportMain(params) {
  return await http.instanceCommon.post("Report/ReportMain", { postString: params });
}
async SaveUserForm(params) {
  return await http.instanceCommon.post("/FormLayout/SaveDynamicForm",  { postString: params });
}
async GetTemplates(params){
  return await http.instance.get("/Form/GetDDLData?"+params);
}
async GetCandidateDetails(params){
  return await http.instance.get("/Candidate/GetCandidateDetails?"+params);
}
async InterviewScheduleNotification(params) {  
  return await http.instance.post("/Candidate/InterviewScheduleNotification",  { postString: params });
}
async GetPostDetail(params) {  
  return await http.instance.get("/Form/GetPostDetail?" + params);
}
async LeaveList (params) {  
  return await http.instance.get("/Candidate/LeaveList?" + params);
}
async ListComment (params) {  
  return await http.instance.get("/Candidate/ListComment?" + params);
}
async GetLanguages(params){   
  return await http.instance.get("/Jobs/GetLanguages?" + params);
}
async GetJobBoardSetup(params){   
  return await http.instance.get("/Jobs/GetJobBoardSetup?" + params);
}
async GetEncryptedPassword(params) {  
  return await http.instance.get("/Form/GetEncryptedPassword?" + params);
}
async GetCloudPath(){
  return await http.instanceCommon.get("/Dashboard/GetCloudPath");
}
async SaveJobBoardSetup(params) {
  return await http.instance.post("/Jobs/SaveJobBoardSetup",  { postString: params });
}
async ManageComment(params) {  
  return await http.instance.post("/Candidate/ManageComment",  { postString: params });
}
async LeaveBalance () {  
  return await http.instance.get("/Candidate/LeaveBalance");
}
async LeaveTypeList (params) {    
  return await http.instance.get("/Candidate/LeaveTypeList?"+params);
}
async GetLeaveType(params) {    
  return await http.instance.get("/Candidate/GetLeaveType?" + params );
}
async GetPMCAByUserId (params) {  
  return await http.instance.get("/Candidate/GetPMCAByUserId?"+params);
}
async GetProjectsByUserID (params) {  
  return await http.instance.get("/Candidate/GetProjectsByUserID?"+params);
}
async LeaveDetail(params) {    
  return await http.instance.get("/Candidate/LeaveDetail?"+params);
}
async GetHRInfo(params) { 
  return await http.instance.get("/Candidate/GetHRInfo?"+params);
}
async ShowShiftTiming(params) {   
  return await http.instance.get("/Candidate/ShowShiftTiming?"+params);
}
async GetAttendenceList(params) {
  return await http.instance.get("/Form/GetAttendenceList?" + params);
}
async GetStatusListByType(params) {
  return await http.instance.get("/Form/GetStatusListByType?" + params);
}
async GetPendingAttendanceApprovalListing(params) {
  return await http.instance.get("/Form/GetPendingAttendanceApprovalListing?" + params);
}
async AddNewComment(params) {
  return await http.instance.post("/Form/AddNewComment", { postString: params });
}
async ListCommentById(params) {
  return await http.instance.get("/Form/GetCommentById?" + params);
}
async AddLeave(params) {
  return await http.instance.post("/Candidate/AddLeave", { postString: params });
}
async AttendanceDetail(params) {
  return await http.instance.get("/Form/AttendanceDetail?" + params);
}
async GetCompanyLocation() {
  return await http.instance.get("/Candidate/GetCompanyLocation");
}
async PayrollGet(params) {
  return await http.instance.get("/Candidate/PayrollGet?" + params);
}
async PayrollList(params) {
  return await http.instance.get("/Candidate/PayrollList?" + params);
}
async PayrollSave(params) {
  return await http.instance.post("/Candidate/PayrollSave", { postString: params });
}
async SaveDetails(params) {
  return await http.instance.post("/Form/SaveDetails", { postString: params });
}
async ApproveRejectAttendance(params) {
  return await http.instance.post("/Form/ApproveRejectAttendance", { postString: params });
}
async LeaveTypeListing(params) {
  return await http.instance.get("/Candidate/LeaveTypeListing?" + params);
}
async CommonUpdateStatus(params) {
  return await http.instanceCommon.post("/Common/CommonUpdateStatus", { postString: params })
}
async LeaveTypeGet(params) {  
  return await http.instance.get("/Candidate/LeaveTypeGet?" + params);
}
async GetAllDesignation() {  
  return await http.instance.get("/Candidate/GetAllDesignation");
}

async GetCheckInFlagList(params) {
  return await http.instance.get("/Form/GetCheckInFlagList?" + params);
}
async GetDisputeListing(params) {
  return await http.instance.get("/Form/GetDisputeListing?" + params);
}
async LeaveAllocGet(params) {  
  return await http.instance.get("/Candidate/LeaveAllocGet?" + params);
}
async GetPriorPendingApprovalGroup(params) {
  return await http.instance.get("/Form/GetPriorPendingApprovalGroup?" + params);
}
async GetTrackDetailCommentByTrackDetailID(params) {
  return await http.instance.get("/Candidate/GetTrackDetailCommentByTrackDetailID?" + params);
}
async GetDisputeAttendanceDetail(params) {
  return await http.instance.get("/Form/GetDisputeAttendanceDetail?" + params);
}
async Save(params) {  
  return await http.instance.post("/Candidate/Save", { postString: params });
}
async LeaveApprovalListing(params) {  
  return await http.instance.get("/Candidate/LeaveApprovalListing?" + params);
}
async SendForApproval(params) {
  return await http.instance.post("/Form/SendForApproval", { postString: params });
}
async GetUsersByApprovalRule(params) {  
  return await http.instance.get("/Candidate/GetUsersByApprovalRule?" + params);
}
async GetLeaveApprovalHistoryListing(params) {  
  return await http.instance.get("/Candidate/History?" + params);
}
async UpdateDisputeAttendanceDetail(params) {
  return await http.instance.post("/Form/UpdateDisputeAttendanceDetail", { postString: params });
}
async ManageTrackDetailComment(params) {
  return await http.instance.post("/Candidate/ManageTrackDetailComment", { postString: params });
}
async GetTrackDetailCommentById(params) {
  return await http.instance.get("/Form/GetTrackDetailCommentById?" + params);
}
async GetPendingDisputeApprovalListing(params) {
  return await http.instance.get("/Form/GetPendingDisputeApprovalListing?" + params);
}
async EditLeaveByApprover(params) { 
  return await http.instance.get("/Candidate/EditLeaveByApprover?" + params);
}
async SaveEditLeaveByApprover(params) {
  return await http.instance.post("/Candidate/SaveEditLeaveByApprover", { postString: params });
}
async ListAttendanceByUser(params) {
  return await http.instance.get("/Form/ListAttendanceByUser?" + params);
}
async DeleteTrackAttendance(params) {  
  return await http.instance.get("/Form/DeleteTrackAttendance?" + params);
}
async ApproverStatusLeave(params) { 
  return await http.instance.get("/Candidate/ListPriorPendingApprovalGroup?" + params);
}
async CommonSendForApproval(params) {  
  return await http.instance.get("/Form/CommonSendForApproval?" + params);
}
async GetCheckInOutLogHistory(params) {
  return await http.instance.get("/Form/GetCheckInOutLogHistory?" + params);
}
async SaveImagesToAzur(params){   
  return await http.instance.post("/Jobs/SaveImagesToAzur" , { postString: params });
}
async GetJobs(params){    
  return await http.instance.get("/Hiring/GetJobs?" + params);
}
async SaveSearchJobHistory(params){   
  return await http.instance.post("/Jobs/SaveSearchJobHistory" , { postString: params });
}
async GetCompanyHRLocation() {
  return await http.instance.get("/Candidate/GetCompanyHRLocation");
}
async LeaveStatusChange(params) {
  return await http.instance.post("/Form/LeaveStatusChange", { postString: params });
}
async AddUpdateAttendance(params) {
  return await http.instance.post("/Candidate/AddUpdateAttendance", { postString: params });
}
async GetHRDDLData(params){
  return await http.instance.get("/Form/GetHRDDLData?"+params);
}
async JobsCountWithCategoy(params) {  
  return await http.instance.get("/Candidate/JobsCountWithCategoy?" + params);
}
async GetLocationWiseJobCount(params){    
  return await http.instance.get("/Hiring/GetLocationWiseJobCount?" + params);
}
}
export default new DataService();
